import { createSlice } from '@reduxjs/toolkit';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import {
	getHomeCategoriesAction,
	getNearByKitchensAction,
	getHomeOffersAction,
	getTopRatedKitchensAction,
	getNewKitchensAction
} from '../actions/home.action';
import { IKitchen } from '../../kitchens/interfaces/kitchen.interface';
import { ICategory } from '../../../common/lookups/interfaces/category.interface';
import { IOffer, IProduct } from '../../../common/interfaces/product.interface';

interface DataReducer {
	categories: Array<ICategory>;
	nearByKitchens: Array<IKitchen>;
	topRatedKitchens: Array<IKitchen>;
	newKitchens: Array<IKitchen>;
	discountedProducts: Array<IProduct>;
	topRatedProducts: Array<IProduct>;
	offers: Array<IOffer>;
	categoryStatus: REDUX_STATUS;
	nearByKitchenStatus: REDUX_STATUS;
	topRatedKitchenStatus: REDUX_STATUS;
	newKitchenStatus: REDUX_STATUS;
	offerStatus: REDUX_STATUS;
}

export const initialState: DataReducer = {
	categories: [],
	nearByKitchens: [],
	topRatedKitchens: [],
	newKitchens: [],
	discountedProducts: [],
	topRatedProducts: [],
	offers: [],
	categoryStatus: REDUX_STATUS.IDLE,
	nearByKitchenStatus: REDUX_STATUS.IDLE,
	topRatedKitchenStatus: REDUX_STATUS.IDLE,
	newKitchenStatus: REDUX_STATUS.IDLE,
	offerStatus: REDUX_STATUS.IDLE
};

const homeSlice = createSlice({
	name: 'home',
	initialState,
	reducers: {
		setHomeState(state) {
			state.nearByKitchenStatus = REDUX_STATUS.IDLE;
			state.topRatedKitchenStatus = REDUX_STATUS.IDLE;
			state.newKitchenStatus = REDUX_STATUS.IDLE;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getHomeCategoriesAction.pending, (state) => {
				state.categoryStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getHomeCategoriesAction.fulfilled, (state, action) => {
				state.categories = action.payload.data;
				state.categoryStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getHomeCategoriesAction.rejected, (state) => {
				state.categoryStatus = REDUX_STATUS.FAILED;
			})

			.addCase(getNearByKitchensAction.pending, (state) => {
				state.nearByKitchenStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getNearByKitchensAction.fulfilled, (state, action) => {
				state.nearByKitchens = action.payload.data;
				state.nearByKitchenStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getNearByKitchensAction.rejected, (state) => {
				state.nearByKitchenStatus = REDUX_STATUS.FAILED;
			})

			.addCase(getTopRatedKitchensAction.pending, (state) => {
				state.topRatedKitchenStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getTopRatedKitchensAction.fulfilled, (state, action) => {
				state.topRatedKitchens = action.payload.data;
				state.topRatedKitchenStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getTopRatedKitchensAction.rejected, (state) => {
				state.topRatedKitchenStatus = REDUX_STATUS.FAILED;
			})

			.addCase(getNewKitchensAction.pending, (state) => {
				state.newKitchenStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getNewKitchensAction.fulfilled, (state, action) => {
				state.newKitchens = action.payload.data;
				state.newKitchenStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getNewKitchensAction.rejected, (state) => {
				state.newKitchenStatus = REDUX_STATUS.FAILED;
			})

			.addCase(getHomeOffersAction.pending, (state) => {
				state.offerStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getHomeOffersAction.fulfilled, (state, action) => {
				state.offers = action.payload.data;
				state.offerStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getHomeOffersAction.rejected, (state) => {
				state.offerStatus = REDUX_STATUS.FAILED;
			});
	}
});

export const homeActions = homeSlice.actions;
export default homeSlice.reducer;
