import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	addNewAddressAction,
	deleteProfileAction,
	getProfileAction,
	getUserAddressAction,
	updateAddressAction,
	updatePasswordAction,
	updateProfileAction,
	uploadImageAction
} from '../action/profile.action';
import { IUser } from '../interface/User.interface';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { IAddress } from '../interface/Address.interface';

export const SweetAlert = withReactContent(Swal);
interface DataReducer {
	profile: IUser;
	addresses: Array<IAddress>;
	profileStatus: REDUX_STATUS;
	addressesStatus: REDUX_STATUS;
}

const initialState: DataReducer = {
	profile: {} as IUser,
	addresses: [],
	profileStatus: REDUX_STATUS.IDLE,
	addressesStatus: REDUX_STATUS.IDLE
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(uploadImageAction.pending, () => { })
			.addCase(uploadImageAction.fulfilled, (_, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			.addCase(uploadImageAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(getProfileAction.pending, (state) => {
				state.profileStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getProfileAction.fulfilled, (state, action) => {
				state.profile = action.payload.data;
				state.profileStatus = REDUX_STATUS.SUCCEEDED;
			})
			.addCase(getProfileAction.rejected, (state, action: PayloadAction<any>) => {
				state.profileStatus = REDUX_STATUS.FAILED;
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(updateProfileAction.pending, () => { })
			.addCase(updateProfileAction.fulfilled, (_, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			.addCase(updateProfileAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(updatePasswordAction.pending, () => { })
			.addCase(updatePasswordAction.fulfilled, (_, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			.addCase(updatePasswordAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(getUserAddressAction.pending, (state) => {
				state.addressesStatus = REDUX_STATUS.PENDING;
			})
			.addCase(getUserAddressAction.fulfilled, (state, action) => {
				state.addressesStatus = REDUX_STATUS.SUCCEEDED;
				state.addresses = action.payload.data;
			})
			.addCase(getUserAddressAction.rejected, (state, action: any) => {
				state.addressesStatus = REDUX_STATUS.FAILED;
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload?.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(addNewAddressAction.pending, () => { })
			.addCase(addNewAddressAction.fulfilled, (state, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.addressesStatus = REDUX_STATUS.IDLE;
			})
			.addCase(addNewAddressAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(updateAddressAction.pending, () => { })
			.addCase(updateAddressAction.fulfilled, (state, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.addressesStatus = REDUX_STATUS.IDLE;
			})
			.addCase(updateAddressAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(deleteProfileAction.pending, () => { })
			.addCase(deleteProfileAction.fulfilled, (_, action) => {
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				localStorage.clear();
				location.reload();
			})
			.addCase(deleteProfileAction.rejected, (_, action: any) => {
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			});
	}
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
