import { AddressDto } from '../modules/profile/dto/address.dto'
import { Api } from '../resources/api-constants'
import CustomAxios from '../utility/customAxios'

export const uploadImageService = (body: any) => {
	return CustomAxios.post(Api.UPLOAD_PROFILE_IMAGE, body)
}

export const getProfileService = () => {
	return CustomAxios.get(Api.GET_PROFILE)
}

export const getUserAddressService = () => {
	return CustomAxios.get(Api.GET_ADDRESS)
}

export const updateProfileService = (body: any) => {
	return CustomAxios.patch(Api.UPDATE_PROFILE, body)
}

export const updatePasswordService = (body: any) => {
	return CustomAxios.patch(Api.UPDATE_PASSWORD, body)
}

export const deleteProfileService = () => {
	return CustomAxios.delete(Api.DELETE_PROFILE)
}

export const addNewAddressService = (data: AddressDto) => {
	return CustomAxios.post(Api.ADD_ADDRESS, data)
}

export const updateAddresservice = (data: AddressDto) => {
	return CustomAxios.put(`${Api.UPDATE_ADDRESS}/${data._id}`, data);
};

export const deleteAddresservice = (id: string) => {
	return CustomAxios.delete(`${Api.DELETE_ADDRESS}/${id}`)
}
