import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authSlice from '../modules/Auth/slice/auth.slice';
import kitchenSlice from '../modules/kitchens/slice/kitchen.slice';
import menuSlice from '../modules/menu/slice/menu.slice';
import homeSlice from '../modules/home/slice/home.slice';
import lookupSlice from '../common/lookups/slice/lookup.slice';
import profileSlice from '../modules/profile/slice/profile.slice';
import cartSlice from '../modules/cart/slice/cart.slice';
import orderSlice from '../modules/Checkout/slice/checkout.slice';
import favoriteSlice from '../modules/favorite/slice/favorite.slice';
import checkoutSlice from '../modules/Checkout/slice/checkout.slice';
import specialMealSlice from '../modules/Special-Meal/slice/special-meal.slice';
import { thunk } from 'redux-thunk';


export const store = configureStore({
	reducer: {
		auth: authSlice,
		kitchen: kitchenSlice,
		menu: menuSlice,
		home: homeSlice,
		profile: profileSlice,
		lookup: lookupSlice,
		cart: cartSlice,
		order: orderSlice,
		favorite: favoriteSlice,
		checkout: checkoutSlice,
		specialMeal: specialMealSlice
	},
	middleware: (getDefaultMiddleware: any) =>
		getDefaultMiddleware({
			thunk,
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & any;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
