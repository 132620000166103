import { Api } from "../../../resources/api-constants";
import customAxios from "../../../utility/customAxios";
import { PaginationExtractor } from "../../../utility/helpers/pagination-extractor";
import { IPagination } from "../../../utility/interface/pagination.interface";

export const getHomeCategories = (query: IPagination) => {
	return customAxios.get(`${Api.LOOKUP_CATEGORIES}${PaginationExtractor(query)}`);
};

export const getNearByKitchenService = (query: IPagination) => {
	return customAxios.get(`${Api.GET_ALL_KITCHENS}${PaginationExtractor(query)}`);
};

export const getTopRatedKitchenService = (query: IPagination) => {
	return customAxios.get(`${Api.GET_ALL_KITCHENS}${PaginationExtractor(query)}`);
};

export const getNewKitchenService = (query: IPagination) => {
	return customAxios.get(`${Api.GET_ALL_KITCHENS}${PaginationExtractor(query)}`);
};

export const getHomeOffersService = (query: IPagination) => {
	return customAxios.get(`${Api.LIST_OFFERS}${PaginationExtractor(query)}`);
};
