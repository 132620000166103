import React from 'react';
import { Provider } from 'react-redux';
import RootComponent from './RootComponent';
import { store } from './store/store';
import { languageInitialization } from './utility/language';
import { AuthProvider } from './providers/Auth.provider';
import { UserProvider } from './providers/User.provider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoadingProvider } from './providers/IsLoading.provider';
import { BrowserRouter } from 'react-router-dom';

const App: React.FC = () => {
	languageInitialization();

	return (
		<BrowserRouter basename="/">
			<GoogleOAuthProvider clientId="551736544959-kflqp6co7cqn7q4jusflorcooh1fvu8u.apps.googleusercontent.com">
				<Provider store={store}>
					<AuthProvider>
						<UserProvider>
							<LoadingProvider>
								<RootComponent />
							</LoadingProvider>
						</UserProvider>
					</AuthProvider>
				</Provider>
			</GoogleOAuthProvider>
		</BrowserRouter>
	);
};

export default App;
