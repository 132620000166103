import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../providers/Auth.provider';

const AuthGuard = (props: { children: any }) => {
	const isAuth = useContext(AuthContext)
	if (!isAuth) {
		return <Navigate to="/login" replace />;
	} else {
		return props.children;
	}
};

export default AuthGuard;
