import { createSlice } from '@reduxjs/toolkit';
import { addKitchenReviewAction, getAllKitchensAction, getKitchenReviewsAction } from '../actions/kitchen.action';
import { IKitchen } from '../interfaces/kitchen.interface';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { IPagination } from '../../../utility/interface/pagination.interface';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { IReview } from '../interfaces/review.interface';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const SuccessAlert = withReactContent(Swal);

interface DataReducer {
	kitchens: Array<IKitchen>;
	reviews: Array<IReview>;
	query: IPagination;
	_metadata: {
		totalPage: number;
		total: number;
	};
	status: REDUX_STATUS;
}

const initialState: DataReducer = {
	kitchens: [],
	reviews: [],
	query: {
		page: 1,
		perPage: 12,
		orderBy: 'createdAt',
		orderDirection: SORT_DIRECTION.ASC
	},
	_metadata: {
		totalPage: 0,
		total: 0
	},
	status: REDUX_STATUS.IDLE
};

const kitchenSlice = createSlice({
	name: 'kitchen',
	initialState,
	reducers: {
		setKitchenQuery: (state, action) => {
			state.query = action.payload;
		},
		setKitchenStatus: (state, action) => {
			state.status = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllKitchensAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(getAllKitchensAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.kitchens = action.payload.data;
				state._metadata = action.payload._metadata.pagination;
			})
			.addCase(getAllKitchensAction.rejected, (state) => {
				state.status = REDUX_STATUS.FAILED;
			})

			.addCase(getKitchenReviewsAction.pending, () => { })
			.addCase(getKitchenReviewsAction.fulfilled, (state, action) => {
				state.reviews = action.payload.data.reviews;
			})
			.addCase(getKitchenReviewsAction.rejected, () => { })

			.addCase(addKitchenReviewAction.pending, () => { })
			.addCase(addKitchenReviewAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, timer: 5000 });
				state.reviews = action.payload.data.reviews;
			})
			.addCase(addKitchenReviewAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, html: action.payload.errors.map((err: any) => err.message + '<br/>'), showConfirmButton: !0, timer: 5000 });
			});
	}
});

export const kitchenActions = kitchenSlice.actions;
export default kitchenSlice.reducer;
