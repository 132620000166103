export const Api = {
	LOGIN_LOCAL: 'user/public/login/customer',
	SIGNUP_LOCAL: 'user/public/signup/customer',
	UPDATE_FIREBASE_TOKEN: 'settings/update',
	CREATE_FIREBASE_TOKEN: 'settings/create',

	LOGIN_VALIDATE: 'user/public/login/validate',

	REFRESH_TOKEN: 'user/auth/refresh',

	UPLOAD_PROFILE_IMAGE: 'user/auth/profile/upload/local',
	GET_PROFILE: 'user/auth/profile',
	UPDATE_PROFILE: 'user/auth/profile/update',
	UPDATE_PASSWORD: 'user/auth/change-password',
	DELETE_PROFILE: 'user/auth/profile/delete',

	GET_ADDRESS: 'address/public/list',
	ADD_ADDRESS: 'address/public/create',
	UPDATE_ADDRESS: 'address/public/update',
	DELETE_ADDRESS: 'address/public/delete',

	LOOKUP_GOVERNORATES: 'geometry/public/governorate',
	LOOKUP_CITIES: 'geometry/public/city',
	LOOKUP_CATEGORIES: 'category/public/list',

	LIST_OFFERS: 'offer/public/list',

	CREATE_CART: 'cart/customer/create',
	GET_CART: 'cart/customer/get',
	UPDATE_ITEM: 'cart/customer/updateItem',
	REMOVE_ITEM: 'cart/customer/removeItem',
	ADD_ITEM: 'cart/customer/addItem',

	ADD_NEW_ORDER: 'order/customer/create',
	VALIDATE_PROMO: 'offer/public/validate',
	LIST_ORDERS: 'order/customer/list',
	CANCEL_ORDER: 'order/customer/cancel',
	VALIDATE_PAYMOB: 'order/customer/paymobCallback',

	GET_ALL_KITCHENS: 'kitchen/public/list',
	GET_KITCHEN_DETAILS: 'kitchen/public/get',
	GET_KITCHEN_REVIEWS: 'review/public/get',
	ADD_REVIEW: 'review/public/create',

	GET_ALL_PRODUCTS: 'product/public/list',
	GET_PRODUCT_DETAILS: 'product/public/get',

	ADD_FAVORITE: 'favorite/customer/create',
	GET_FAVORITE: 'favorite/customer/get',

	GET_SPECIAL_ORDER: 'special-order/customer/get',
	CREATE_SPECIAL_ORDER: 'special-order/customer/create',
	SELECT_SPECIAL_OFFER: 'special-order/customer/select-offer',
	CANCEL_SPECIAL_OFFER: 'special-order/customer/cancel'
}
