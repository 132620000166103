import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getHomeCategories,
	getHomeOffersService,
	getNearByKitchenService,
	getNewKitchenService,
	getTopRatedKitchenService
} from '../services/home.service';
import { IPagination } from '../../../utility/interface/pagination.interface';

export const getHomeCategoriesAction = createAsyncThunk('getHomeCategoriesAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await getHomeCategories(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getNearByKitchensAction = createAsyncThunk('getNearByKitchensAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getNearByKitchenService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getTopRatedKitchensAction = createAsyncThunk('getTopRatedKitchensAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getTopRatedKitchenService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getNewKitchensAction = createAsyncThunk('getNewKitchensAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getNewKitchenService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getHomeOffersAction = createAsyncThunk('getHomeOffersAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await getHomeOffersService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
