import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ISpecialMeal } from '../interfaces/special-meal.interface'
import { cancelSpecialOfferAction, createSpecialMealAction, getSpecialMealAction, selectSpecialOfferAction } from '../actions/special-order.action'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
	specialMeal: ISpecialMeal
	status: REDUX_STATUS,
	offerSelected: boolean,
	hasSpecial: boolean
}

const initialState: DataReducer = {
	specialMeal: {} as ISpecialMeal,
	status: REDUX_STATUS.IDLE,
	offerSelected: false,
	hasSpecial: localStorage.getItem('SP') ? true : false
}

const specialMealSlice = createSlice({
	name: 'specialMeal',
	initialState,
	reducers: {
		setSpecialMeal(state: DataReducer, action: PayloadAction<ISpecialMeal>) {
			state.specialMeal = action.payload
			state.status = REDUX_STATUS.IDLE
		},
		setSelectedOffer(state: DataReducer, action: PayloadAction<boolean>){
			state.offerSelected = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSpecialMealAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(getSpecialMealAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED
				state.specialMeal = action.payload.data
			})
			.addCase(getSpecialMealAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			
			.addCase(createSpecialMealAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				localStorage.setItem("SP", action.payload.data._id)
				state.hasSpecial = true;
			})
			.addCase(createSpecialMealAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			
			.addCase(selectSpecialOfferAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				state.offerSelected = true;
				state.status = REDUX_STATUS.IDLE
			})
			.addCase(selectSpecialOfferAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			
			.addCase(cancelSpecialOfferAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				localStorage.removeItem("SP");
				state.status = REDUX_STATUS.IDLE
			})
			.addCase(cancelSpecialOfferAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
	}
})

export const specialMealActions = specialMealSlice.actions
export default specialMealSlice.reducer
